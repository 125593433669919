.Document {
  counter-reset: sectionCount subsectionCount;
  background-color: #fff;
}

@media not print {
  .Document {
    padding-block: 3em 5em;
    padding-inline: 5em;
  }
}

.Document [contenteditable] {
  outline: 0 solid #0000;
}

.Document .NewPage {
  counter-increment: pageCount 1;
}

.Document .NewPage:before {
  content: counter(pageCount);
  text-align: center;
  position: absolute;
  left: -.5rem;
  right: .5rem;
  transform: translateY(-3rem);
}

.Document:after {
  counter-increment: pageCount 1;
  content: counter(pageCount);
  text-align: center;
  position: absolute;
  left: -.5rem;
  right: .5rem;
  transform: translateY(2rem);
}

.Document .Section > h2 {
  counter-increment: sectionCount 1;
  counter-reset: subsectionCount;
}

.Document .Section > h2:before {
  content: counter(sectionCount) " ";
}

.Document .SubSection > h3 {
  counter-increment: subsectionCount 1;
}

.Document .SubSection > h3:before {
  content: counter(sectionCount) "." counter(subsectionCount) " ";
}

.MakeTitle {
  --block: .67em;
  padding-block: var(--block);
  margin: 0;
}

.MakeTitle:hover {
  position: relative;
}

.MakeTitle:hover:after {
  color: #000a;
  width: 1em;
  height: 1em;
  top: calc(100% - var(--block));
  content: "add";
  border: 1px solid;
  border-radius: 20%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-family: Material Symbols Outlined;
  font-size: .75em;
  display: flex;
  position: absolute;
  left: 100%;
}

.Brackets {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  flex-wrap: nowrap;
  align-items: stretch;
  font-family: math;
  display: inline-flex;
}

.Brackets :not(sup, sub) {
  text-align: center;
  vertical-align: middle;
}

.Brackets > span {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  flex-wrap: nowrap;
  align-items: center;
  font-family: math;
  display: inline-flex;
}

.Brackets > span :not(sup, sub) {
  text-align: center;
  vertical-align: middle;
}

.Brackets.empty > span:before {
  content: " ";
  width: .5em;
  height: .5em;
  background-color: #bd000029;
  border: 1px dashed #bd00008f;
  display: inline-block;
}

.Brackets.empty:focus > span:before {
  background-color: #bd00004d;
  border: 1px dashed #bd0000c4;
}

.Brackets:before {
  content: "";
  cursor: text;
  width: 7px;
  background-image: url("OpenRoundBracket.5e4bdb10.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.Brackets:after {
  content: "";
  cursor: text;
  width: 7px;
  background-image: url("CloseRoundBracket.0b225c0f.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.Brackets[data-square="true"]:after {
  background-image: url("CloseSquareBracket.3f37c6ec.svg");
}

.Brackets[data-square="true"]:before {
  background-image: url("OpenSquareBracket.85281518.svg");
}

.Comma {
  margin-inline: 0 .25em;
}

.SubFraction {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  text-indent: 0;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-inline: .25em;
  font-family: math;
  display: inline-block;
}

.SubFraction :not(sup, sub) {
  text-align: center;
  vertical-align: middle;
}

.SubFraction > hr {
  border: 1px solid #5e5e5e;
  margin: auto;
  margin-inline: 4px;
  padding-block: 0;
  padding-inline: 6px;
}

.SubFraction.empty:before {
  content: " ";
  width: 1em;
  height: 1em;
  background-color: #bd000029;
  border: 1px dashed #bd00008f;
  display: inline-block;
}

.SubFraction.empty:focus:before {
  background-color: #bd00004d;
  border: 1px dashed #bd0000c4;
}

.Function {
  font-family: math;
}

.Function > span {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  flex-wrap: nowrap;
  align-items: stretch;
  font-family: math;
  display: inline-flex;
}

.Function > span :not(sup, sub) {
  text-align: center;
  vertical-align: middle;
}

.Function > span > span {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  flex-wrap: nowrap;
  align-items: stretch;
  font-family: math;
  display: inline-flex;
}

.Function > span > span :not(sup, sub) {
  text-align: center;
  vertical-align: middle;
}

.Function > span:before {
  content: "";
  cursor: text;
  width: 7px;
  background-image: url("OpenSquareBracket.85281518.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.Function > span:after {
  content: "";
  cursor: text;
  width: 7px;
  background-image: url("CloseSquareBracket.3f37c6ec.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-block;
}

.Function.empty > span > span:before {
  content: " ";
  width: .5em;
  height: .5em;
  background-color: #bd000029;
  border: 1px dashed #bd00008f;
  display: inline-block;
}

.Function.empty > span :focus > span:before {
  background-color: #bd00004d;
  border: 1px dashed #bd0000c4;
}

.Function > select {
  appearance: none;
  background-color: #0000;
  border: none;
  display: inline;
}

.Function > select::ms-expand {
  display: none;
}

.InlineMaths {
  text-indent: 0;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  flex-wrap: nowrap;
  align-items: stretch;
  font-family: math;
  display: inline-flex;
}

.InlineMaths :not(sup, sub) {
  text-align: center;
  vertical-align: middle;
}

.InlineMaths.empty:before {
  content: " ";
  width: .5em;
  height: .5em;
  background-color: #bd000029;
  border: 1px dashed #bd00008f;
  display: inline-block;
}

.InlineMaths.empty:focus:before {
  background-color: #bd00004d;
  border: 1px dashed #bd0000c4;
}

.Maths {
  overflow-wrap: none;
  text-align: center;
  vertical-align: middle;
  font-family: math;
}

.Maths :not(sup, sub) {
  text-align: center;
  vertical-align: middle;
}

.Number {
  font-family: math;
}

.Power {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  flex-wrap: nowrap;
  align-items: stretch;
  font-family: math;
  display: inline-flex;
}

.Power :not(sup, sub) {
  text-align: center;
  vertical-align: middle;
}

.Power.empty:before {
  content: " ";
  width: .5em;
  height: .5em;
  background-color: #bd000029;
  border: 1px dashed #bd00008f;
  display: inline-block;
}

.Power.empty:focus:before {
  background-color: #bd00004d;
  border: 1px dashed #bd0000c4;
}

.SingleCharEditableElement {
  align-items: center;
  margin-inline: .25em;
  font-family: math;
  display: inline-flex;
}

.Variable {
  margin-inline: 0;
}

.Vector:before {
  content: "→";
  font-size: .65em;
  position: absolute;
  transform: translateY(-.65em);
}



.NewPage {
  --block: .5em;
  break-after: page;
  width: calc(100% + 10rem);
  border-color: var(--background-color);
  border-width: var(--block);
  border-style: solid;
  margin-block: 4rem;
  overflow: visible;
  transform: translateX(-5rem);
}

@media print {
  .NewPage {
    visibility: hidden;
  }
}

.NewPage:hover {
  position: relative;
}

.NewPage:hover:after {
  color: var(--color);
  width: 1em;
  height: 1em;
  top: calc(100% - var(--block));
  content: "add";
  border: 1px solid;
  border-radius: 20%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-family: Material Symbols Outlined;
  font-size: .75em;
  display: flex;
  position: absolute;
  left: 100%;
}

.Paragraph {
  text-align: justify;
  text-indent: 2em;
  margin: 0;
}

.Section h2 {
  --block: .83em;
}

.Section h3 {
  --block: 1em;
}

.Section > :is(h2, h3):first-child {
  padding-block: var(--block);
  margin: 0;
}

.Section > :is(h2, h3):first-child:hover {
  position: relative;
}

.Section > :is(h2, h3):first-child:hover:after {
  color: #000a;
  width: 1em;
  height: 1em;
  top: calc(100% - var(--block));
  content: "add";
  border: 1px solid;
  border-radius: 20%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-family: Material Symbols Outlined;
  font-size: .75em;
  display: flex;
  position: absolute;
  left: 100%;
}

.TextNormal {
  font-family: initial;
}



main:has(#root) {
  grid-template-columns: 1fr 50rem 1fr;
  grid-template-areas: "⚫ 📄 ➡️";
  justify-content: center;
  gap: .5em;
  margin-top: 1rem;
  display: grid;
}

@media (display-mode: window-controls-overlay) {
  main:has(#root) {
    margin-top: 3rem;
  }
}

@media print {
  #table_of_contents {
    display: none;
  }
}

#table_of_contents {
  -webkit-user-select: none;
  user-select: none;
  grid-area: ⚫;
  margin: .5em;
  font-family: sans-serif;
}

#table_of_contents .title {
  cursor: pointer;
  font-size: 1.5em;
}

#table_of_contents .section {
  cursor: pointer;
  counter-increment: tocSectionCount 1;
  counter-reset: tocSubsectionCount;
  font-size: 1.25em;
}

#table_of_contents .section:before {
  content: counter(tocSectionCount) " ";
}

#table_of_contents .subsection {
  cursor: pointer;
  counter-increment: tocSubsectionCount 1;
}

#table_of_contents .subsection:before {
  content: counter(tocSectionCount) "." counter(tocSubsectionCount) " ";
}

#root {
  max-width: 50rem;
  color-scheme: light;
  color: #000;
  margin-inline: .2em;
  grid-area: 📄;
  margin-bottom: 1rem;
}

#edit_controls {
  width: max-content;
  grid-area: ➡️;
}

#edit_controls > div {
  flex-direction: column;
  display: flex;
  position: sticky;
  top: 2.8rem;
}

@media (width < calc(53rem + 24px)) {
  main:has(#root) {
    grid-template-columns: none;
    grid-template-areas: "📄";
  }

  main:has(#edit_controls):before, #edit_controls {
    display: none;
  }
}

#latex:modal > textarea {
  background-color: var(--textarea-background-color);
  width: calc(100vw - 5.5em);
  height: calc(100vh - 5.5em);
  max-width: 1200px;
  max-height: 700px;
  white-space: pre-wrap;
  resize: none;
  border: 1px solid #111;
  border-radius: .5em 0 0 .5em;
  outline: none;
  padding: .5em;
  font-family: monospace;
  overflow-y: scroll;
}

#articles {
  gap: .6em;
  margin-inline: .6em;
  display: flex;
}

#articles > a {
  all: unset;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

#articles > a:hover {
  box-shadow: 0 0 5px #111;
}

#articles > a > div {
  border: 1px solid gray;
  border-radius: .2em;
  padding: .5em;
}

#articles > a > div > div:first-of-type {
  margin-bottom: .2em;
  font-size: larger;
}

#articles > a > div > div:nth-of-type(2) {
  font-size: smaller;
}

#articles > a > div > div:nth-of-type(3) {
  text-align: end;
  opacity: .5;
  font-family: monospace;
}

:root {
  --color-scheme: dark;
  --color: white;
  --background-color: #24292e;
  --icon-button-hover-color: #eefcfd11;
  --confirm-color: green;
  --deny-color: red;
  --textarea-background-color: #1112;
  --cmd-pallet-input-background-color: #32373c;
  --cmd-pallet-background-color: #3d4248;
  --snackbar-background-color: #323940;
  color-scheme: var(--color-scheme);
  accent-color: var(--background-color);
  scroll-behavior: smooth;
}

@media (prefers-color-scheme: light) {
  :root {
    --color-scheme: light;
    --color: black;
    --background-color: #fff;
    --icon-button-hover-color: #a5abab33;
    --confirm-color: green;
    --deny-color: red;
    --textarea-background-color: #d5d3d322;
    --cmd-pallet-input-background-color: #e5e5e5;
    --cmd-pallet-background-color: #f2f2f2;
    --snackbar-background-color: #dde2e8;
  }
}

body {
  background-color: var(--background-color);
  margin: 0;
}

main {
  margin-top: 1rem;
}

button:has(.material-symbols-outlined) {
  -webkit-user-select: none;
  user-select: none;
  color: var(--color);
  height: 24px;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 2px;
  outline: none;
  margin: 0;
  padding: 0;
}

button:has(.material-symbols-outlined):disabled {
  opacity: .3;
  cursor: not-allowed;
}

button:has(.material-symbols-outlined):not(:disabled):hover {
  background-color: var(--icon-button-hover-color);
}

button.required > .material-symbols-outlined {
  animation: .82s cubic-bezier(.36, .07, .19, .97) both shake;
  transform: rotate(5deg);
}

@keyframes shake {
  10%, 90% {
    transform: rotate(-5deg);
  }

  20%, 80% {
    transform: rotate(10deg);
  }

  30%, 50%, 70% {
    transform: rotate(-20deg);
  }

  40%, 60% {
    transform: rotate(20deg);
  }
}

input[type="checkbox"] {
  width: 1.25em;
  height: 1.25em;
}

input[type="checkbox"]:not(:checked) {
  opacity: .2;
}

#navbar {
  background-color: var(--background-color);
  height: 1.7em;
  border-bottom: 1px solid gray;
  justify-content: space-between;
  margin-top: 0;
  padding-top: .3em;
  padding-bottom: .1em;
  display: flex;
  position: sticky;
  top: 0;
}

#navbar:before, #buttons {
  content: "";
  width: 20%;
  margin-inline: 1em;
}

#command {
  height: 1.5em;
  max-width: 600px;
  width: 40%;
  font-family: monospace;
  position: relative;
}

#command:after {
  float: left;
  content: "search";
  margin-left: .1em;
  font-family: Material Symbols Outlined;
  font-size: 1.25em;
}

#command_input {
  width: 100%;
  height: 1.8em;
  background-color: var(--icon-button-hover-color);
  border-color: #0000;
  border-radius: .2em;
  margin: 0;
  font-family: monospace;
  position: absolute;
  left: 0;
}

#command_input:not(:focus-visible) {
  color: #0000;
}

#command_input:focus-visible, #command:active > #command_input {
  background-color: var(--cmd-pallet-input-background-color);
  z-index: 1;
  outline: none;
}

#command_palette {
  padding-top: 2.3em;
  padding-bottom: 5px;
  max-width: 600px;
  width: 40%;
  max-height: 200px;
  background-color: var(--cmd-pallet-background-color);
  border-radius: 0 0 .5rem .5rem;
  padding-inline: 5px;
  display: none;
  position: fixed;
  top: 0;
  overflow: hidden;
  transform: translateX(-5px);
  box-shadow: 0 0 5px #111;
}

#command_palette > div {
  max-height: 200px;
  overflow-y: hidden;
}

#command_palette > div > ul {
  --scrollbar-track: var(--cmd-pallet-background-color);
  max-height: 200px;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

#command_palette > div > ul > li {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  --cmd-palette-icon: "";
  margin-top: .2em;
  margin-left: .5em;
  font-size: 1.2em;
  list-style: inside;
}

#command_palette > div > ul > li::marker {
  content: var(--cmd-palette-icon) " ";
  font-family: Material Symbols Outlined;
  font-size: calc(1em - 4px);
}

#command_input:focus-visible ~ #command_palette, #command:active > #command_palette {
  display: block;
}

#buttons {
  justify-content: flex-end;
  display: flex;
}

@media (display-mode: window-controls-overlay) {
  #navbar {
    left: env(titlebar-area-x);
    top: env(titlebar-area-y);
    width: env(titlebar-area-width);
    height: env(titlebar-area-height);
    -webkit-app-region: drag;
    app-region: drag;
    box-sizing: border-box;
    border-bottom: 0 solid gray;
    padding-top: .2em;
    padding-bottom: 0;
    position: fixed;
  }

  #command {
    width: 50%;
  }

  #command_palette {
    width: calc(.5 * env(titlebar-area-width));
  }

  #command, #buttons > button {
    -webkit-app-region: no-drag;
    app-region: no-drag;
  }

  main {
    margin-top: calc(1rem + env(titlebar-area-height));
  }
}

:modal {
  background-color: var(--background-color);
  border: none;
  border-radius: 1rem;
  overflow: auto;
  box-shadow: 0 0 5px #111;
}

:modal::backdrop {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

* {
  --scrollbar-thumb: #afafafad;
  --scrollbar-track: transparent;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

::-webkit-scrollbar {
  width: .75rem;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border: 3px solid var(--scrollbar-track);
}

body {
  --scrollbar-track: var(--background-color);
  overflow-y: scroll;
}

body::-webkit-scrollbar-thumb {
  border-top: calc(2.1rem + 3px) solid var(--scrollbar-track);
}

body:has(:modal) {
  margin-right: .75rem;
  overflow-y: hidden;
}

aside {
  width: 19rem;
  height: 100%;
  pointer-events: none;
  flex-flow: column;
  justify-content: flex-end;
  display: flex;
  position: fixed;
  top: 0;
}

#snackbar {
  flex-flow: column;
  justify-content: flex-end;
  gap: .5em;
  padding: .4em;
  display: flex;
}

#snackbar > div {
  background-color: var(--snackbar-background-color);
  border-radius: .3em;
  padding: .5em;
  font-family: monospace;
  transition: transform .25s ease-in-out;
  transform: translateX(-25rem);
  box-shadow: 0 0 8px #242424;
}

#snackbar > div.show {
  pointer-events: visiblePainted;
  transform: translateX(0);
}

#snackbar > div.confirm {
  padding-left: 1em;
  padding-right: 1em;
}

#snackbar > div.confirm > div {
  justify-content: flex-end;
  display: flex;
}

#snackbar > div.confirm > div > button {
  border: none;
  border-radius: .2em;
  margin-left: .5em;
  padding-block: .3em;
  padding-inline: .5em;
  font-family: monospace;
}

#snackbar > div.confirm > div > button:first-of-type {
  background-color: var(--confirm-color);
}

#snackbar > div.confirm > div > button:nth-of-type(2) {
  background-color: var(--deny-color);
}

/*# sourceMappingURL=index.6e4faa65.css.map */
