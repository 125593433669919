.NewPage {
  --block: 0.5em;

  overflow: visible;
  break-after: page;
  width: calc(100% + 10rem);
  transform: translateX(-5rem);
  border-color: var(--background-color);
  margin-block: 4rem;
  border-width: var(--block);
  border-style: solid;

  @media print {
    visibility: hidden;
  }

  &:hover {
    position: relative;

    &::after {
      color: var(--color);
      font-size: 0.75em;
      width: 1em;
      height: 1em;
      position: absolute;
      top: calc(100% - var(--block));
      left: 100%;
      border: 1px solid currentColor;
      border-radius: 20%;
      font-family: 'Material Symbols Outlined';
      content: 'add';
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
    }
  }
}
