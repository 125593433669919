.Document {
  background-color: white;
  counter-reset: sectionCount subsectionCount;

  @media not print {
    padding-block-start: 3em;
    padding-block-end: 5em;
    padding-inline: 5em;
  }

  [contenteditable] {
    outline: 0px solid transparent;
  }

  .NewPage {
    counter-increment: pageCount 1;

    &::before {
      content: counter(pageCount);
      position: absolute;
      left: -0.5rem;
      right: 0.5rem;
      text-align: center;
      transform: translateY(-3rem);
    }
  }

  &::after {
    counter-increment: pageCount 1;
    content: counter(pageCount);
    position: absolute;
    left: -0.5rem;
    right: 0.5rem;
    text-align: center;
    transform: translateY(2rem);
  }

  .Section > h2 {
    counter-increment: sectionCount 1;
    counter-reset: subsectionCount;

    &::before {
      content: counter(sectionCount) ' ';
    }
  }

  .SubSection > h3 {
    counter-increment: subsectionCount 1;

    &::before {
      content: counter(sectionCount) '.' counter(subsectionCount) ' ';
    }
  }
}
