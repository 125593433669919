.Function {
  font-family: math;
  /* margin-left: 0.2em; */

  > span {
    font-family: math;

    display: inline-flex;
    align-items: stretch;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    :not(sup, sub) {
      text-align: center;
      vertical-align: middle;
    }

    > span {
      font-family: math;

      display: inline-flex;
      align-items: stretch;
      flex-wrap: nowrap;
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      :not(sup, sub) {
        text-align: center;
        vertical-align: middle;
      }
    }

    &::before {
      content: '';
      cursor: text;
      display: inline-block;
      width: 7px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('./OpenSquareBracket.svg');
    }

    &::after {
      content: '';
      cursor: text;
      display: inline-block;
      width: 7px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url('./CloseSquareBracket.svg');
    }
  }

  &.empty > span {
    > span::before {
      content: ' ';
      border: 1px dashed #bd00008f;
      background-color: #bd000029;
      display: inline-block;
      width: 0.5em;
      height: 0.5em;
    }

    :focus > span::before {
      border: 1px dashed #bd0000c4;
      background-color: #bd00004d;
    }
  }

  > select {
    background-color: transparent;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: inline;
  }

  > select::ms-expand {
    display: none;
  }
}
