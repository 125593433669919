.Brackets {
  font-family: math;

  display: inline-flex;
  align-items: stretch;
  flex-wrap: nowrap;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  :not(sup, sub) {
    text-align: center;
    vertical-align: middle;
  }

  > span {
    font-family: math;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    :not(sup, sub) {
      text-align: center;
      vertical-align: middle;
    }
  }

  &.empty > span::before {
    content: ' ';
    border: 1px dashed #bd00008f;
    background-color: #bd000029;
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
  }

  &.empty:focus > span::before {
    border: 1px dashed #bd0000c4;
    background-color: #bd00004d;
  }

  &::before {
    content: '';
    cursor: text;
    display: inline-block;
    width: 7px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('./OpenRoundBracket.svg');
  }

  &::after {
    content: '';
    cursor: text;
    display: inline-block;
    width: 7px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('./CloseRoundBracket.svg');
  }
}

.Brackets[data-square='true'] {
  &::after {
    background-image: url('./CloseSquareBracket.svg');
  }
  &::before {
    background-image: url('./OpenSquareBracket.svg');
  }
}
