@import './src/index.css';

main:has(#root) {
  display: grid;
  grid-template-columns: 1fr 50rem 1fr;
  gap: 0.5em;
  grid-template-areas: '⚫ 📄 ➡️';
  justify-content: center;
  margin-top: 1rem;
}

@media (display-mode: window-controls-overlay) {
  main:has(#root) {
    margin-top: 3rem;
  }
}

@media print {
  #table_of_contents {
    display: none;
  }
}

#table_of_contents {
  grid-area: ⚫;
  font-family: sans-serif;
  margin: 0.5em;
  user-select: none;
}

#table_of_contents .title {
  cursor: pointer;
  font-size: 1.5em;
}

#table_of_contents .section {
  cursor: pointer;
  font-size: 1.25em;
  counter-increment: tocSectionCount 1;
  counter-reset: tocSubsectionCount;
}

#table_of_contents .section:before {
  content: counter(tocSectionCount) ' ';
}

#table_of_contents .subsection {
  cursor: pointer;
  counter-increment: tocSubsectionCount 1;
}

#table_of_contents .subsection:before {
  content: counter(tocSectionCount) '.' counter(tocSubsectionCount) ' ';
}

#root {
  grid-area: 📄;
  max-width: 50rem;
  margin-inline: 0.2em;
  color-scheme: light;
  color: black;
  margin-bottom: 1rem;
}

#edit_controls {
  width: max-content;
  grid-area: ➡️;
}

#edit_controls > div {
  position: sticky;
  top: 2.8rem;
  display: flex;
  flex-direction: column;
}

@media (width < calc(53rem + 24px)) {
  main:has(#root) {
    grid-template-columns: none;
    grid-template-areas: '📄';
  }

  main:has(#edit_controls)::before {
    display: none;
  }

  #edit_controls {
    display: none;
  }
}

#latex:modal > textarea {
  border: 1px solid #111;
  background-color: var(--textarea-background-color);
  border-radius: 0.5em 0 0 0.5em;
  padding: 0.5em;
  width: calc(100vw - 5.5em);
  height: calc(100vh - 5.5em);
  max-width: 1200px;
  max-height: 700px;
  white-space: pre-wrap;
  font-family: monospace;
  overflow-y: scroll;
  resize: none;
  outline: none;
}
